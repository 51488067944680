<template>
  <form :class="$style.delivery" @submit.prevent="$emit('save')">
    <h2 v-if="title" :class="$style.title">
      {{ title }}
    </h2>
    <Checkbox
      :class="$style.input"
      :checked="deliveryIsAvailable"
      @change="$emit('update:delivery-is-available', $event)"
    >
      Доставка доступна
    </Checkbox>
    <adw-input
      :class="$style.input"
      :value="deliveryHelp"
      type="text"
      placeholder="Текст подсказки"
      @input="$emit('update:delivery-help', $event)"
    />
    <adw-input
      :class="$style.input"
      :value="deliveryPrice"
      type="number"
      placeholder="Стоимость доставки"
      @input="$emit('update:delivery-price', parseFloat($event))"
    />
    <adw-input
      v-if="showInput"
      :class="$style.input"
      :value="deliveryBorderPrice"
      type="number"
      placeholder="Пороговая сумма заказа"
      @input="$emit('update:delivery-border-price', parseFloat($event))"
    />
    <div :class="$style.actions">
      <Button type="submit" small> Сохранить </Button>
    </div>
  </form>
</template>

<script>
import Button from '@/components/atoms/Button.vue'
import Checkbox from '@/components/atoms/Checkbox.vue'

export default {
  components: {
    Button,
    Checkbox,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    deliveryIsAvailable: {
      type: Boolean,
      default: false,
    },
    deliveryHelp: {
      type: String,
      default: '',
    },
    deliveryPrice: {
      type: Number,
      default: 0,
    },
    deliveryBorderPrice: {
      type: Number,
      default: 0,
    },
    showInput: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" module>
.delivery {
  padding: 1rem;
  .title,
  .input {
    max-width: none;
    margin-bottom: 1rem;
  }
  .actions {
    display: flex;
    justify-content: flex-end;
  }
  .devider {
    margin: 1.5rem 0;
    border-top: 0.063rem solid $extra-light-gray;
  }
}
</style>
