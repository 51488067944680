<template>
  <form :class="$style.cartDiscount" @submit.prevent="$emit('save')">
    <h2 :class="$style.title">Настроить скидку на оплату наличными</h2>
    <Checkbox
      :class="$style.input"
      :checked="ieCartDiscountIsAvailable"
      @change="$emit('update:ie-cart-discount-is-available', $event)"
    >
      Скидка доступна для корзины ИП
    </Checkbox>
    <Input
      :class="$style.input"
      :value="ieCartDiscount"
      placeholder="Скидка для корзины ИП, %"
      @input="$emit('update:ie-cart-discount', parseFloat($event))"
    />
    <Checkbox
      :class="$style.input"
      :checked="llcCartDiscountIsAvailable"
      @change="$emit('update:llc-cart-discount-is-available', $event)"
    >
      Скидка доступна для корзины ООО
    </Checkbox>
    <Input
      :class="$style.input"
      :value="llcCartDiscount"
      placeholder="Скидка для корзины ООО, %"
      @input="$emit('update:llc-cart-discount', parseFloat($event))"
    />
    <Checkbox
      :class="$style.input"
      :checked="rwCartDiscountIsAvailable"
      @change="$emit('update:rw-cart-discount-is-available', $event)"
    >
      Скидка доступна для корзины УС
    </Checkbox>
    <Input
      :class="$style.input"
      :value="rwCartDiscount"
      placeholder="Скидка для корзины УС, %"
      @input="$emit('update:rw-cart-discount', parseFloat($event))"
    />
    <div :class="$style.devider"></div>
    <div :class="$style.actions">
      <Button type="submit" small> Сохранить </Button>
    </div>
  </form>
</template>

<script>
import Input from '@/components/atoms/Input.vue'
import Button from '@/components/atoms/Button.vue'
import Checkbox from '@/components/atoms/Checkbox.vue'
export default {
  components: {
    Input,
    Button,
    Checkbox,
  },
  props: {
    ieCartDiscountIsAvailable: {
      type: Boolean,
      default: false,
    },
    ieCartDiscount: {
      type: Number,
      default: 0,
    },
    llcCartDiscountIsAvailable: {
      type: Boolean,
      default: false,
    },
    llcCartDiscount: {
      type: Number,
      default: 0,
    },
    rwCartDiscountIsAvailable: {
      type: Boolean,
      default: false,
    },
    rwCartDiscount: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style lang="scss" module>
.cartDiscount {
  padding: 1rem;
  .title,
  .input {
    margin-bottom: 1rem;
  }
  .actions {
    display: flex;
    justify-content: flex-end;
  }
  .devider {
    margin: 1.5rem 0;
    border-top: 0.063rem solid $extra-light-gray;
  }
}
</style>
