<template>
  <form :class="$style.requesites" @submit.prevent="$emit('save')">
    <h2 v-if="title" :class="$style.title">
      {{ title }}
    </h2>
    <Input
      :class="$style.input"
      :value="value.name"
      placeholder="Название компании"
      @input="$emit('input', { ...value, name: $event })"
    />
    <Input
      :class="$style.input"
      :value="value.legalAddress"
      placeholder="Юридический адрес"
      @input="$emit('input', { ...value, legalAddress: $event })"
    />
    <Input
      :class="$style.input"
      :value="value.unp"
      placeholder="ИНН/ЕГРПОУ/УНП/БИН"
      @input="$emit('input', { ...value, unp: $event })"
    />
    <Input
      :class="$style.input"
      :value="value.kpp"
      placeholder="КПП"
      @input="$emit('input', { ...value, kpp: $event })"
    />
    <Input
      :class="$style.input"
      :value="value.ogrn"
      placeholder="ОГРН/ОГРНИП"
      @input="$emit('input', { ...value, ogrn: $event })"
    />
    <Input
      :class="$style.input"
      :value="value.paymentAccount"
      placeholder="Расчетный счет"
      @input="$emit('input', { ...value, paymentAccount: $event })"
    />
    <Input
      :class="$style.input"
      :value="value.bankName"
      placeholder="Наименование банка"
      @input="$emit('input', { ...value, bankName: $event })"
    />
    <Input
      :class="$style.input"
      :value="value.bik"
      placeholder="БИК"
      @input="$emit('input', { ...value, bik: $event })"
    />
    <Input
      :class="$style.input"
      :value="value.corAccount"
      placeholder="Кор счет"
      @input="$emit('input', { ...value, corAccount: $event })"
    />
    <Input
      :class="$style.input"
      :value="value.phone"
      placeholder="Телефон"
      @input="$emit('input', { ...value, phone: $event })"
    />
    <Input
      :class="$style.input"
      :value="value.email"
      placeholder="Email"
      @input="$emit('input', { ...value, email: $event })"
    />
    <div :class="$style.devider"></div>
    <div :class="$style.actions">
      <Button type="submit" small> Сохранить </Button>
    </div>
  </form>
</template>

<script>
import Input from '@/components/atoms/Input.vue'
import Button from '@/components/atoms/Button.vue'
export default {
  components: {
    Input,
    Button,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => ({
        name: '',
        legalAddress: '',
        unp: '',
        kpp: '',
        ogrn: '',
        paymentAccount: '',
        bankName: '',
        bik: '',
        corAccount: '',
        phone: '',
        email: '',
      }),
    },
  },
}
</script>

<style lang="scss" module>
.requesites {
  padding: 1rem;
  .title,
  .input {
    margin-bottom: 1rem;
  }
  .actions {
    display: flex;
    justify-content: flex-end;
  }
  .devider {
    margin: 1.5rem 0;
    border-top: 0.063rem solid $extra-light-gray;
  }
}
</style>
