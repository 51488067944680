<template>
  <form :class="$style.container" @submit.prevent="submitForm">
    <Loader :isLoading="isLoading" />
    <modal
      :class="$style.modal"
      name="edit-delivery-modal"
      scrollable
      height="auto"
    >
      <EditDeliveryModal
        title="Настроить доставку"
        :delivery-is-available.sync="form.deliveryIsAvailable"
        :delivery-help.sync="form.deliveryHelp"
        :delivery-price.sync="form.deliveryPrice"
        :delivery-border-price.sync="form.deliveryBorderPrice"
        @save="submitForm"
      />
    </modal>
    <modal
      :class="$style.modal"
      name="edit-delivery-to-client-modal"
      scrollable
      height="auto"
    >
      <EditDeliveryModal
        title="Настроить доставку клиенту"
        :delivery-is-available.sync="form.deliveryToClientIsAvailable"
        :delivery-help.sync="form.deliveryToClientHelp"
        :delivery-price.sync="form.deliveryToClientPrice"
        :delivery-border-price.sync="form.deliveryToClientBorderPrice"
        @save="submitForm"
      />
    </modal>
    <modal
      :class="$style.modal"
      name="edit-delivery-express-modal"
      scrollable
      height="auto"
    >
      <EditDeliveryModal
        title="Настроить срочную доставку"
        :delivery-is-available.sync="form.deliveryExpressIsAvailable"
        :delivery-help.sync="form.deliveryExpressHelp"
        :delivery-price.sync="form.deliveryExpressPrice"
        :showInput="false"
        @save="submitForm"
      />
    </modal>
    <modal
      :class="$style.modal"
      name="edit-ie-requesites-modal"
      scrollable
      height="auto"
    >
      <EditRequesitesModal
        v-model="form.ieRequesites"
        title="Настроить реквизиты склада ИП"
        @save="submitForm"
      />
    </modal>
    <modal
      :class="$style.modal"
      name="edit-llc-requesites-modal"
      scrollable
      height="auto"
    >
      <EditRequesitesModal
        v-model="form.llcRequesites"
        title="Настроить реквизиты склада ООО"
        @save="submitForm"
      />
    </modal>
    <modal
      :class="$style.modal"
      name="edit-cart-discount-modal"
      scrollable
      height="auto"
    >
      <EditCartDiscountModal
        :ie-cart-discount-is-available.sync="form.ieCartDiscountIsAvailable"
        :ie-cart-discount.sync="form.ieCartDiscount"
        :llc-cart-discount-is-available.sync="form.llcCartDiscountIsAvailable"
        :llc-cart-discount.sync="form.llcCartDiscount"
        :rw-cart-discount-is-available.sync="form.rwCartDiscountIsAvailable"
        :rw-cart-discount.sync="form.rwCartDiscount"
        @save="submitForm"
      />
    </modal>
    <section :class="$style.section">
      <h2 :class="$style.name">Каталог</h2>
      <Checkbox v-model="form.catalogInfoIsShown" :class="$style.input">
        Показать уведомление
      </Checkbox>
      <Input
        v-model="form.catalogInfoText"
        :class="$style.input"
        textarea
        placeholder="Текст уведомления"
      />
    </section>
    <section :class="$style.section">
      <h2 :class="$style.name">Текст кнопки "Написать менеджеру"</h2>
      <Input
        v-model="form.managerLinkBtnText"
        :class="$style.input"
        placeholder="Текст внутри кнопки"
      />
    </section>
    <section :class="$style.section">
      <h2 :class="$style.name">Заказы</h2>
      <Input
        v-model="form.selfDeliveryDays"
        :class="$style.input"
        placeholder="Доступные дни для самовывоза"
      />
      <Input
        v-model="form.selfDeliveryTime"
        :class="$style.input"
        placeholder="Доступные часы для самовывоза"
      />
      <div :class="$style.delivery">
        <Button small @click="$modal.show('edit-delivery-modal')">
          Настроить доставку
        </Button>
        <Button small @click="$modal.show('edit-delivery-to-client-modal')">
          Настроить доставку клиенту
        </Button>
        <Button small @click="$modal.show('edit-delivery-express-modal')">
          Настроить срочную доставку клиенту
        </Button>
      </div>
      <Checkbox v-model="form.ieCashPaymentOn" :class="$style.input">
        Оплата наличными корзины ИП
      </Checkbox>
      <Checkbox v-model="form.ieBillPaymentOn" :class="$style.input">
        Оплата на расчетный счет корзины ИП
      </Checkbox>
      <Checkbox v-model="form.llcCashPaymentOn" :class="$style.input">
        Оплата наличными корзины ООО
      </Checkbox>
      <Checkbox v-model="form.llcBillPaymentOn" :class="$style.input">
        Оплата на расчетный счет корзины ООО
      </Checkbox>
      <Checkbox v-model="form.rwCashPaymentOn" :class="$style.input">
        Оплата наличными корзины УС
      </Checkbox>
      <Checkbox v-model="form.rwBillPaymentOn" :class="$style.input">
        Оплата на расчетный счет УС
      </Checkbox>
      <Checkbox v-model="form.rwDiscountIsAvailable" :class="$style.input">
        Использовать спец цену для товаров с удаленного склада
      </Checkbox>
      <Button small @click="$modal.show('edit-cart-discount-modal')">
        Настроить скидку на оплату наличными
      </Button>
    </section>
    <section :class="$style.section">
      <h2 :class="$style.name">Реквизиты</h2>
      <div :class="$style.requesites">
        <Button small @click="$modal.show('edit-ie-requesites-modal')">
          Настроить реквизиты склада ИП
        </Button>
        <Button small @click="$modal.show('edit-llc-requesites-modal')">
          Настроить реквизиты склада ООО
        </Button>
      </div>
    </section>
    <section :class="$style.section">
      <h2 :class="$style.name">Кол-во дней до автоблокировки пользователя</h2>
      <el-input-number
        v-model="form.daysToDealerAccountsAutoBan"
        :class="$style.input"
        type="number"
        :min="1"
        placeholder="1"
      />
    </section>
    <section :class="$style.section">
      <h2 :class="$style.name">
        Введите информационный текст для товаров в резверве
      </h2>
      <el-input
        v-model="form.labelReserve"
        :class="$style.textarea"
        placeholder="Текст для товаров в резерве"
        type="textarea"
        resize="none"
      />
    </section>
    <div :class="$style.actions">
      <Button type="submit">Сохранить</Button>
      <Button type="reset" color="outline-secondary" @click="$router.back()">
        Назад
      </Button>
    </div>
  </form>
</template>

<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications.js'

import Loader from '@/components/atoms/LoadingBar.vue'
import Input from '@/components/atoms/Input.vue'
import Button from '@/components/atoms/Button.vue'
import Checkbox from '@/components/atoms/Checkbox.vue'
import EditDeliveryModal from '@/components/moleculs/SellersDeliverySettingsModal.vue'
import EditRequesitesModal from '@/components/moleculs/SellersRequesitesSettingsModal.vue'
import EditCartDiscountModal from '@/components/moleculs/SellersDiscountSettingsModal.vue'
export default {
  components: {
    Loader,
    Input,
    Button,
    Checkbox,
    EditDeliveryModal,
    EditRequesitesModal,
    EditCartDiscountModal,
  },
  mixins: [notifications],
  data() {
    return {
      isLoading: false,
      form: {
        catalogInfoIsShown: false,
        catalogInfoText: '',
        managerLinkBtnText: '',
        ieCashPaymentOn: false,
        ieBillPaymentOn: false,
        llcCashPaymentOn: false,
        llcBillPaymentOn: false,
        rwCashPaymentOn: false,
        rwBillPaymentOn: false,
        rwDiscountIsAvailable: false,
        selfDeliveryDays: '',
        selfDeliveryTime: '',
        deliveryIsAvailable: false,
        deliveryHelp: '',
        deliveryPrice: 0,
        deliveryBorderPrice: 0,
        deliveryToClientIsAvailable: false,
        deliveryToClientHelp: '',
        deliveryToClientPrice: 0,
        deliveryToClientBorderPrice: 0,
        deliveryExpressIsAvailable: false,
        deliveryExpressHelp: '',
        deliveryExpressPrice: 0,
        ieRequesites: {
          name: '',
          legalAddress: '',
          unp: '',
          kpp: '',
          ogrn: '',
          paymentAccount: '',
          bankName: '',
          bik: '',
          corAccount: '',
          phone: '',
          email: '',
        },
        llcRequesites: {
          name: '',
          legalAddress: '',
          unp: '',
          kpp: '',
          ogrn: '',
          paymentAccount: '',
          bankName: '',
          bik: '',
          corAccount: '',
          phone: '',
          email: '',
        },
        ieCartDiscountIsAvailable: false,
        ieCartDiscount: 0,
        llcCartDiscountIsAvailable: false,
        llcCartDiscount: 0,
        rwCartDiscountIsAvailable: false,
        rwCartDiscount: 0,
        daysToDealerAccountsAutoBan: 0,
        labelReserve: '',
      },
    }
  },
  async created() {
    await this.getSellersSettings()
  },
  methods: {
    async submitForm() {
      this.isLoading = true

      const { error } =
        await delivery.AddwineCore.SellersSettingsActions.update(this.form)

      this.isLoading = false

      if (error) {
        return
      }

      this.showNotification('Настройки успешно изменены', 'success')

      this.$modal.hide('edit-delivery-modal')
      this.$modal.hide('edit-delivery-to-client-modal')
      this.$modal.hide('edit-delivery-express-modal')
      this.$modal.hide('edit-ie-requesites-modal')
      this.$modal.hide('edit-llc-requesites-modal')
      this.$modal.hide('edit-cart-discount-modal')

      await this.getSellersSettings()
    },
    async getSellersSettings() {
      this.isLoading = true

      const { value, error } =
        await delivery.AddwineCore.SellersSettingsActions.get()

      this.isLoading = false

      if (error) {
        return
      }

      this.form = value
    },
  },
}
</script>

<style lang="scss" module>
.container {
  max-width: 24rem;
  margin: auto;
  padding: 1rem;
  background: $white;
  .section {
    margin-bottom: 2rem;
    .name,
    .input {
      margin-bottom: 1rem;
    }
    .textarea {
      height: 10rem;

      & > textarea {
        height: 100%;
      }
    }
    .delivery,
    .requesites {
      display: flex;
      flex-direction: column;
    }
    .delivery {
      > button {
        margin-bottom: 1rem;
      }
    }
    .requesites {
      > button {
        &:first-child {
          margin-bottom: 1rem;
        }
      }
    }
  }
  .actions {
    display: flex;
    > [type='submit'] {
      flex: 1 1 auto;
      margin-right: 1.5rem;
    }
  }
}
</style>
